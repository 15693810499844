<template>
  <v-dialog
    :value="$store.state.popup.show"
    persistent
    max-width="290"
    light
    content-class="no-print"
  >
    <v-card light>
      <v-card-title class="subtitle-2 justify-center">
        {{ $store.state.popup.title }}
      </v-card-title>
      <v-divider />
      <v-card-text v-if="$store.state.popup.type === 'confirm_value'" class="pt-2 px-6 text-center">
        <v-text-field
          ref="vPopup"
          :rules="[rules.required, rules.counter]"
          label="Input"
          hide-details
          autocomplete="off"
          @keyup="$store.commit('SET_POPUP_VALUE', $event.target.value)"
        />
      </v-card-text>
      <v-card-text v-else class="py-4 px-6 text-center">
        {{ $store.state.popup.text }}
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-4">
        <v-btn
          v-if="$store.state.popup.type === 'confirm' || $store.state.popup.type === 'confirm_value'"
          color="red darken-1"
          text
          class="text-capitalize caption"
          @click="$store.commit('SET_POPUP', { confirmResult: false, show: false })"
        >
          {{ $store.state.popup.bCancel }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="darken-1"
          dark
          small
          depressed
          class="text-capitalize blue"
          @click="Ok()"
        >
          {{ $store.state.popup.bOk }}
        </v-btn>
        <v-spacer v-if="$store.state.popup.type !== 'confirm' && $store.state.popup.type !== 'confirm_value'" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
      /* eslint-disable-next-line */
      counter: value => value ? true : false
    }
  }),
  methods: {
    Ok () {
      if (this.$store.state.popup.type === 'confirm_value') {
        if (!this.$store.state.popup.value) {
          this.$refs.vPopup.focus()
          return false
        }
      }
      this.$store.commit('SET_POPUP', { confirmResult: true, show: false })
    }
  }
}
</script>
